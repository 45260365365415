class SwiperSlide {
    constructor() {
        this.events();
    }
    events() {
        this.initswiperPostDetail();
        this.initSectionMediaSlide();
        this.initswiperCencept();
        var swiperHomeContact = new Swiper(".home-contact-swiper", {
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".contact-swiper-button-next",
                prevEl: ".contact-swiper-button-prev",
            },
        });
        var swiperHomContactThumbs = new Swiper(".home-contact-swiper-control", {});

        swiperHomeContact.controller.control = swiperHomContactThumbs;
        swiperHomContactThumbs.controller.control = swiperHomeContact;
    }
    initswiperPostDetail() {
        var swiperPostDetail = new Swiper(".latestnewsSwiper", {
            slidesPerView: 3,
            spaceBetween: 30,
            slideToClickedSlide: true,
            // loop: true,
            // loopedSlides: 6,
            // scrollbar: {
            //     el: ".swiper-scrollbar",
            //     hide: false,
            // },
            // autoplay: {
            //     delay: 5000,
            //     disableOnInteraction: true,
            // },
            navigation: {
                nextEl: '.latest-news__swiper-button-next',
                prevEl: '.latest-news__swiper-button-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 15,
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            },
        });
    }
    initSectionMediaSlide(){
        var swiper_gallery = new Swiper(".slide-gallery", {
            slidesPerView: 2,
            loop: true,
            centeredSlides:true,
            spaceBetween: 20,
            fadeEffect: {
                crossFade: true
              },

            speed: 400,
            navigation: {
                nextEl: ".section-slide__next",
                prevEl: ".section-slide__prev"
            },
            pagination: {
                el: ".slide-gallery .slide-swiper-pagination",
                type: "fraction",
            },
            // pagination: {
            //     el: ".section-media-swiper-pagination",
            //     clickable: true,
            //     renderBullet: function (index, className) {
            //         return '<span class="' + className + '">' + (index + 1) + "</span>";
            //     },
            // },
            // breakpoints: {
            //     480: {
            //         slidesPerView: 1,
                   
            //     },

            //     0: {

            //         slidesPerView: 1,
            //         centeredSlides: true,
                   
            //     },

            // },
        });

    }
    initswiperCencept(){
        var swiper = new Swiper(".swiperConcept", {
            slidesPerView: 1.5,
            spaceBetween: 20,
            // loop: true,
            autoplay: {
                 delay: 5000,
                 disableOnInteraction: false,
            },
            scrollbar: {
              el: ".swiper-scrollbar",
              hide: true,
            },
          });
    }

}
export default SwiperSlide
